export default class VolunteersIndex {
    constructor() {
        this.datatable = null;
        this.bindEvents();
        this.initDataTable();
        window.application.setOnDataChangeListener(this);
    }

    bindEvents() {
        $("#graph_email").knob();
        $("#graph_mooc").knob();

        $('#tagFilter').select2({
            //placeholder: I18n.t("backoffice.volunteers.index.filter_by_tags"),
            allowClear: true
        });

        $('#tagFilter').on('change', () => {
            this.datatable.ajax.reload();
        });
    }

    onDataChanged(data) {
        if (this.datatable) {
            this.datatable.ajax.reload();
        }
    }

    onDestroy() {
        if(this.datatable !== null){
            this.datatable.destroy();
        }
    }

    handleAction(url, method) {
        $.ajax({
            url: url,
            method: method,
            success: () => {
                this.datatable.ajax.reload();
            },
            error: (xhr) => {
                console.error('Error:', xhr);
            }
        });
    }

    initDataTable() {
        if(this.datatable !== null){
            this.datatable.destroy();
        }

        this.datatable = $('.js-export-datatable').DataTable({
            processing: true,
            serverSide: true,
            ajax: {
                url: window.location.pathname,
                data: function(d) {
                    d.search = d.search.value;
                    d.tags = $('#tagFilter').val();
                    return d;
                }
            },
            dom: 'rtp',
            responsive: false,
            language: require('../utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            pageLength: 20,
            ordering: false,
            columns: [
                { 
                    data: null,
                    render: function(data) {
                        return `<img src="${data.thumbnail}" class="avatar-md rounded-circle"/>`;
                    }
                },
                { 
                    data: 'last_name',
                    render: function(data, type, row) {
                        return `<a href="volunteers/${row.id}">${data}</a>`;
                    }
                },
                { 
                    data: 'first_name',
                    render: function(data, type, row) {
                        return `<a href="volunteers/${row.id}">${data}</a>`;
                    }
                },
                { data: 'email' },
                { data: 'confirmed_at' },
                { data: 'phone' },
                { 
                    data: 'tags',
                    render: function(data) {
                        return data.map(tag => 
                            `<span class="badge badge-primary">${tag.name}</span>`
                        ).join(' ');
                    }
                },
                { 
                    data: null,
                    render: function(data) {
                        if (data.mooc_passed) {
                            let badge = `<span class="badge badge-success">
                                ${I18n.t("common.yes")} (${data.mooc_passed_at})`;
                            if (data.temporary_mooc) {
                                badge += `<br><br>${I18n.t("common.until")} ${data.mooc_end_date}`;
                            }
                            badge += '</span>';
                            return badge;
                        } else {
                            return `<span class="badge badge-danger">${I18n.t("common.no")}</span>`;
                        }
                    }
                },
                { data: 'participations' },
                { 
                    data: null,
                    render: (data) => {
                        let menu = `<div class="dropleft">
                            <button class="btn btn-outline-dark dropdown-toggle" type="button" data-toggle="dropdown">
                                <i class="mdi mdi-dots-horizontal"></i>
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="volunteers/${data.id}">
                                    <i class="mdi mdi-eye mr-1"></i>${I18n.t("common.show")}
                                </a>`;
                        menu += `
                                <a class="dropdown-item" href="volunteers/${data.id}/edit">
                                    <i class="mdi mdi-pencil mr-1"></i>${I18n.t("common.edit")}
                                </a>`;

                        if (!data.mooc_passed) {
                            menu += `
                                <a class="dropdown-item action-link" href="volunteers/${data.id}/mooc_passed?temporary_mooc=false" data-method="patch">
                                    <i class="mdi mdi-check mr-1 text-success"></i>${I18n.t("event_management.volunteers.validate_mooc")}
                                </a>
                                <a class="dropdown-item action-link" href="volunteers/${data.id}/mooc_passed?temporary_mooc=true" data-method="patch">
                                    <i class="mdi mdi-timer-outline mr-1 text-success"></i>${I18n.t("event_management.volunteers.validate_mooc_temporary")}
                                </a>`;
                        }

                        if (!data.confirmed_at) {
                            menu += `
                                <a class="dropdown-item text-warning action-link" href="volunteers/${data.id}/validate_email" data-method="patch">
                                    ${I18n.t("event_management.volunteers.confirm_email")} <i class="mdi mdi-check"></i>
                                </a>`;
                        }

                        if (data.mooc_passed) {
                            menu += `
                                <a class="dropdown-item action-link" href="volunteers/${data.id}/devalidate_mooc" data-method="put">
                                    <i class="mdi mdi-close text-danger"></i> ${I18n.t("event_management.volunteers.devalidate_mooc")}
                                </a>`;
                        }

                        menu += '</div></div>';
                        return menu;
                    }
                }
            ],
            buttons: [
                {
                    extend: 'csv',
                    exportOptions: {
                        columns: [1,2,3,4,5,6,7,8]
                    },
                    title: 'Volontaires'
                }
            ],
            drawCallback: () => {
                // Bind click events to action links
                $('.action-link').off('click').on('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const link = $(e.currentTarget);
                    console.log('Action link clicked -> '+link.attr('href'));
                    this.handleAction(link.attr('href'), link.data('method'));
                });
            }
        });

        let $search = $('#customSearch');
        if($search.length !== 0){
            $search.keyup(() => {
                this.datatable.search($search.val()).draw();
            });
        }

        $("#export_volunteers").on("click", () => {
            this.datatable.button('.buttons-csv').trigger();
        });
    }
}