require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
const $ = window.$ = window.jQuery = require("jquery");
window.I18n = require("i18n-js");

import "datatables.net-bs4/css/dataTables.bootstrap4.css"
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.css"
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.css"
import "datatables.net-select-bs4/css/select.bootstrap4.css"

import "dropzone/dist/dropzone.css"
window.Dropzone = require("dropzone/dist/dropzone");

import "popper.js"
require("jquery-toast-plugin")
import "jquery-toast-plugin/dist/jquery.toast.min.css"
require("../backoffice/utils/theme/pages/toastr.init")
import "bootstrap-select/dist/css/bootstrap-select.css"
import "bootstrap"
import "../backoffice/assets/styles/backoffice.scss"

const select2 = require('select2');
global.select2 = select2;

require( 'select2/dist/js/i18n/fr.js');
import 'jquery-validation'

import Application from "../backoffice/Application";

import "../backoffice/utils/plugins/dropify/dist/js/dropify"
import "../backoffice/utils/plugins/dropify/dist/css/dropify.min.css"

import "leaflet/dist/leaflet.css"
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.min';
import "leaflet-draw/dist/leaflet.draw";
import "leaflet-draw/dist/leaflet.draw.css";


require("bootstrap-select");
require("datatables.net/js/jquery.dataTables")
require("datatables.net-bs4/js/dataTables.bootstrap4.min")
require("datatables.net-responsive/js/dataTables.responsive")
require("datatables.net-responsive-bs4/js/responsive.bootstrap4.min")
require("datatables.net-buttons/js/dataTables.buttons")
require("datatables.net-buttons-bs4/js/buttons.bootstrap4.min")
require("datatables.net-buttons/js/buttons.html5")
require("datatables.net-buttons/js/buttons.flash")
require("datatables.net-buttons/js/buttons.print")
require("datatables.net-keytable/js/dataTables.keyTable")
require("datatables.net-select/js/dataTables.select")
require("pdfmake/build/pdfmake")
require("pdfmake/build/vfs_fonts")

require("trix")
require("@rails/actiontext")

import "jquery-knob"

window.application = new Application();

require("../backoffice/utils/theme/vendor");
$(document).on('turbolinks:load', function(event) {

    $(document).on("submit", "form[method=get]", function(e) {
        Turbolinks.visit(this.action+(this.action.indexOf('?') === -1 ? '?' : '&')+$(this).serialize());
        return false;
    });

    Turbolinks.setProgressBarDelay(500);

    let $body = $("body");
    application.setController($body.data("controller"));
    application.setAction($body.data("action"));
    application.pageChanged();

    require("../backoffice/utils/theme/app");
    $.App.init();
    Waves.init();
});