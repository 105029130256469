export default class VolunteersForm {

    constructor() {
        this.bindEvents();
    }
  
    bindEvents() {
      $("#tags-select").select2({
        multiple: true,
        closeOnSelect: false,
        templateSelection: function(data, container) {
          $(container).css("background-color", "#272b61");
          return data.text;
        },
      });
    }
    
    onDataChanged(data) {
    }
  
    onDestroy() {
      $("#tags-select").select2("destroy");
  
    }
  
  }